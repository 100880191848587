export const Terminos = [
  {
    text: 'Conceptos del contrato',
    link: 'https://silverapp.s3.amazonaws.com/legal/Conceptos_Contrato_V1.pdf',
  },
  {
    text: 'Pagaré',
    link: 'https://silverapp.s3.amazonaws.com/legal/Pagare_Silver_App.pdf',
  },
  {
    text: 'Política de privacidad y tratamiento de información y/o datos personales',
    link: 'https://silverapp.s3.amazonaws.com/legal/Politica_Privacidad_V1.pdf',
  },
  {
    text: 'Términos y condiciones',
    link: 'https://silverapp.s3.amazonaws.com/legal/Terminos_Condiciones_Silver_V1.pdf',
  },
  {
    text: 'Autorización consulta en centrales',
    link: 'https://silverapp.s3.amazonaws.com/legal/Autorizacion_Consulta_Centrales_V1.pdf',
  },
];
