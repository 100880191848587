import React from "react";
import { Link } from "react-router-dom";
import {
  FacebooLogo,
  InstagramLogo,
  LogoSuperBlanco,
  Silver_App_blanoynegro,
  PlayStore,
} from "../../assets/logo/footer";
import "./Footer.css";
export const Footer = () => {
  return (
    <div className="Footer_Configuration">
      <footer id="Footer">
        <section id="Footer_info">
          <article className="Footer_info_logo">
            <img
              src={Silver_App_blanoynegro}
              alt="Logo v2"
              className="footer-logo-silver"
            />
          </article>
          <article className="espacio_informacion_enlaces">
            <h2>Conoce</h2>
            <Link to="/somos">
              <p>Acerca de nosotros</p>
            </Link>
            <Link to="/preguntas">
              <p>Preguntas frecuentes</p>
            </Link>
          </article>
          <article className="juntos espacio_descarga">
            <h2>Descargala</h2>
            <a
              href="https://play.google.com/store/apps/details?id=co.silvercredit.silverapp"
              target="_blank"
            >
              <img
                src={PlayStore}
                alt="Descarga play store"
                className="footer_logos descargala"
              />
            </a>
          </article>
          <article className="juntos espacio_redes">
            <h2>Siguenos</h2>
            <div className="espacio_redes_enlaces">
              <a
                href="https://www.facebook.com/silverappco "
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={FacebooLogo}
                  alt="Siguenos en Facebook"
                  className="footer_logos siguenos"
                />
              </a>
              <a
                href="https://www.instagram.com/silverappco/ "
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={InstagramLogo}
                  alt="Siguenos en instagram"
                  className="footer_logos siguenos"
                />
              </a>
            </div>
          </article>
        </section>
        <hr />
        <section id="Footer_terms">
          <div className="Footer_terms_derechos">
            Silver App - Todos los Derechos Reservados 2023
          </div>
          <div className="Footer_terms_enlaces otros">
            <Link to="/" className="terms-p">
              Características
            </Link>
            <Link to="/" className="terms-p">
              Condiciones de uso
            </Link>
            <Link to="legal" className="terms-p">
              Términos y Condiciones
            </Link>
            <Link to="/" className="terms-p">
              Aviso de privacidad
            </Link>
          </div>
          <div className="Footer_terms_industria">
            <img src={LogoSuperBlanco} alt="Logo v2" className=" zimagen" />
          </div>
        </section>
        <hr />
        <section id="footer_mas_Detalles">
          <p>
            El desembolso de tu crédito lo recibirás en menos de 24 horas si tu
            cuenta es de Bancolombia, en el horario de lunes a viernes de 8:00
            am a 6:00 pm y sábados de 8:00 am hasta la 1:00 pm. Si tu cuenta es
            de otros bancos el plazo de desembolso de tu crédito será máximo de
            48 horas de acuerdo con los ciclos interbancarios de ACH.
          </p>
          <p>
            Para un préstamo modalidad microcrédito por un monto inicial
            solicitado de $3,000,000, tasa de interés efectiva anual 59.21%,
            tasa de la fianza en 15%, más IVA del 19% de la tasa de la fianza,
            amortizada en 12 cuotas fijas pagadas, con una frecuencia de pago
            quincenal y a una tasa de 1,96% quincenal vencido. El valor total a
            financiar del crédito queda en $3,651,856, las 12 cuotas fijas a
            pagar quedan en un valor de $344,399 y el plazo sería de 180 días.
          </p>
        </section>
      </footer>
    </div>
  );
};
