import React from 'react';
import {caracteristicas} from '../../../assets/img/Somos';

export const Portada2 = () => {
  return (
    <div className="portada_2_section_container">
      <img
        src={caracteristicas}
        alt="Portada de fondo"
        className="portada2_img_background"
      />
      <section className="somos_section_container portada_2_section_content">
        <h1 className="tituloh1_2 titulo">
          Lo que <span>caracteriza</span> a silver App
        </h1>
        <div className="portada_2_section_content_info">
          <ul>
            <li className="parrafop_2">Créditos a bajas tasas de interés.</li>
            <li className="parrafop_2">
              Solicitud de créditos a través de un proceso totalmente digital,
              sin intermediarios, fácil, seguro y rápido.
            </li>
            <li className="parrafop_2">
              Facilidad de pago quincenal, con plazo máximo de 12 meses.
            </li>
            <li className="parrafop_2">
              Ampliación de cupo por buen comportamiento de pago o si
              suministras información complementaria.
            </li>
            <li className="parrafop_2">
              Nuestro servicio de billetera virtual te ofrece los beneficios de
              transferencias de Wallet a Wallet, transferencias interbancarias,
              transferencias con código QR y generar un ahorro.
            </li>
          </ul>
        </div>
        <div className="portada_2_section_content_semi_logo">
          <h1>
            <span>Tu solución</span>
          </h1>
          <h1>Digital Fácil</h1>
        </div>
      </section>
    </div>
  );
};

//REVISÓN 1 LISTA
