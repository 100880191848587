import React from 'react';
import {Formulario} from './components/Formulario';
import {Portada} from './components/Portada';
import './styles/Portada.css';
import './styles/formulario.css';
export const Opiniones = () => {
  return (
    <section>
      <Portada />
      <Formulario />
    </section>
  );
};

/*
Revisión 1 lista

A la espera de reunión para coordinar envío de correo electrónico

Nota: 
[ ] Queda pendiente configurar linea whatsapp para comunicarse
*/
