import {useState} from 'react';
import {questions} from '../../../preguntas';
/**
 * Contiene la lógica del componente PreguntasSection.jsx:
 *
 * - inputPregunta: el valor del input que se usa para filtrar
 *
 * - respuesta: el valor de la pregunta seleccionada. En este caso guarda el objeto correspondiente a la pregunta
 *
 * - preguntasFiltradas: preguntas filtradas ....
 *
 * - seleccionarPregunta: función del state para seleccionar la pregunta
 *
 * - onChangeInputPregunta: función que captura el onchange del input y realiza el filtro de las preguntas importadas segun el value del input
 */
export const usePreguntasSection = () => {
  const [respuesta, seleccionarPregunta] = useState(null);
  const [preguntasFiltradas, setPreguntasFiltradas] = useState(questions || []);
  const [inputPregunta, setInputPragunta] = useState('');
  const onChangeInputPregunta = e => {
    const value = e.target.value;
    setInputPragunta(value);
    const dataFiltrada = questions?.filter(pregunta => {
      const minuscula = value.toLowerCase();
      const aminuscula = pregunta?.q?.toLowerCase();
      return aminuscula.includes(minuscula);
    });
    setPreguntasFiltradas(dataFiltrada);
  };
  return {
    inputPregunta,
    respuesta,
    preguntasFiltradas,
    seleccionarPregunta,
    onChangeInputPregunta,
  };
};
