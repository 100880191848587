export const Section = ({text, archivos}) => {
  return (
    <article className="legal_section">
      <h2>{text}</h2>
      <div>
        {archivos?.map((archivo, ind) => (
          <div className="legal_descarga_archivo" key={ind}>
            <a href={archivo.link} target={'_blank'} rel="noreferrer">
              <span>{archivo.text}</span>
            </a>
          </div>
        ))}
      </div>
    </article>
  );
};
