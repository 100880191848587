export const questions = [
  {
    q: '¿Quiénes somos?',
    a: (
      <>
        <p>
          Somos Silver App una aplicación al acceso de todos, buscamos
          fortalecer la inclusión financiera en Colombia ofreciendo sus
          productos de crédito y billetera virtual. Sus productos cuentan con
          las siguientes características:
        </p>

        <ul>
          <li>Créditos a bajas tasas de interés.</li>
          <li>
            Solicitud de créditos a través de un proceso totalmente digital, sin
            intermediarios, fácil, seguro y rápido.
          </li>
          <li>Facilidad de pago quincenal, con plazo máximo de 12 meses.</li>
          <li>
            Ampliación de cupo por buen comportamiento de pago o si suministras
            información complementaria.
          </li>
          <li>
            Nuestro servicio de billetera virtual te ofrece los beneficios de
            transferencias de Wallet a Wallet, transferencias interbancarias,
            transferencias con código QR y generar un ahorro.
          </li>
        </ul>
        <p>
          Los propietarios y administradores de la plataforma SILVER APP somos
          SILVER HOLDINGS S.A.S., identificada con NIT No. 901.539.173 – 9; una
          sociedad jurídica debidamente constituida ubicada en la calle 53 No 46
          - 192 Piso 6 centro comercial Portal del Prado, Barranquilla –
          Colombia.
        </p>
      </>
    ),
  },

  {
    q: '¿Cuáles son los costos asociados a Silver App?',
    a: (
      <>
        <p>
          Gozar de la billetera virtual que te ofrece Silver App es totalmente
          gratis, los costos asociados a tu microcrédito variaran dependiendo de
          las políticas establecidas por Silver App, pero siempre teniendo en
          cuenta que los intereses nunca superaran la tasa máxima establecida
          por la Superintendencia Financiera de Colombia. Esta tasa estará
          siempre disponible en nuestra página web.
        </p>
        <p>
          Al momento de hacer tu solicitud te informamos detalladamente los
          valores asociados a tu crédito, esto lo podrás evidenciar en el
          simulador de crédito el cual arroja valores según los campos
          ingresados y seleccionados por ti como monto y número de cuotas.
        </p>
        <ol>
          <li>
            *Tasa máxima de interés efectiva anual vigente para julio 2022:
            59.21%EA
          </li>
          <li>
            Fianza: Este costo representa una garantía para tu crédito, ten en
            cuenta que el valor de esta fianza es del 15% sobre el monto de tu
            crédito el cual pagaras dentro del plazo de tu crédito. Esta fianza
            es un servicio prestado a través de Fondo de garantías del Caribe.
          </li>
        </ol>
      </>
    ),
  },
  {
    q: '¿Las actividades de Silver App quién la supervisa?',
    a: (
      <>
        <p>
          Silver App es una aplicación fundada y de propiedad de Silver Holdings
          S.A.S quien es una sociedad debidamente constituida según los
          lineamientos legales del territorio nacional colombiano, por lo
          anterior se encuentra vigilada por la Superintendencia de sociedades y
          la superintendencia de industria y comercio.
        </p>
        <p>
          Silver desarrolla actividades comerciales que no están bajo la
          vigilancia de la Super Intendencia Financiera de Colombia, ya que no
          recibe dinero de sus clientes, utiliza capital propio para otorgar
          todos sus créditos.
        </p>
      </>
    ),
  },
  {
    q: '¿Qué es una Silver Wallet?',
    a: (
      <>
        <p>
          La Silver Wallet es nuestro servicio gratuito de billetera virtual que
          te ofrece los siguientes beneficios:
        </p>
        <ul>
          <li>
            Contar con el desembolso del dinero de tu crédito en tu billetera
            virtual de manera inmediata.
          </li>
          <li>Transferencias de Wallet a Wallet.</li>
          <li>Transferencias interbancarias.</li>
          <li>Transferencias con código QR.</li>
          <li>Generar un ahorro dentro de tu Silver App.</li>
        </ul>
      </>
    ),
  },
  {
    q: '¿Qué es un microcrédito?',
    a: (
      <>
        <p>
          Es un crédito 100% digital, fácil, rápido y seguro en el cual puedes
          elegir el monto desde $ 100.000 hasta $ 5.000.000* con un plazo de
          pago seleccionado por el usuario de 3 a 12 meses con frecuencia de
          pago quincenal.
        </p>
        <p>
          (*) La aprobación del monto aprobado está sujeta a políticas de
          probación de Silver App.
        </p>
      </>
    ),
  },
  {
    q: '¿Cuáles son los requisitos para obtener un microcrédito?',
    a: (
      <>
        <p>Para acceder a nuestro servicio de crédito se requiere:</p>
        <ul>
          <li>Ser colombiano mayor de edad con domicilio en nuestro país.</li>
          <li>Contar con un dispositivo móvil</li>
          <li>Tener correo electrónico y línea celular de uso personal.</li>
          <li>
            Suministrar su información personal, leer, entender y aceptar la
            política de privacidad, tratamiento de información y/o datos
            personales, consulta en centrales de riesgos, declaración en origen
            de fondos, entre otros.
          </li>
          <li>
            Suscribir el contrato y todos los documentos anexos que hacen parte
            integral de este.
          </li>
        </ul>
      </>
    ),
  },
  {
    q: '¿En cuánto tiempo puedo disponer de mi dinero?',
    a: (
      <>
        <p>
          Silver Holdings S.A.S. quién es propietario de la aplicación Silver
          App desembolsará tu dinero en un plazo no mayor a 24 horas hábiles en
          tu billetera virtual (Silver Wallet) Cuando desees realizar una
          transferencia interbancaria el plazo será de 48 horas hábiles.
        </p>
        <p>
          Nuestros procesos son 100% virtuales sin embargo es importante tener
          en cuenta que en muchos de ellos dependemos de terceros, como en el
          caso de las transferencias electrónicas de fondos entre bancos la cual
          dependerá de los de ACH.
        </p>
      </>
    ),
  },
  {
    q: '¿Qué pasa si me atraso en el pago de mi crédito?',
    a: (
      <>
        <p>
          En caso de no realizar el pago de tu cuota y entrar en un
          incumplimiento igual o mayor a 20 días calendario y haber sido
          informado y/o notificado sobre la altura de la mora de tu crédito,
          serás reportado ante centrales de riesgo crediticio, sin perjuicio de
          las demás acciones de cobro y legales pertinentes que ejerceremos
          sobre dicha obligación.
        </p>
      </>
    ),
  },
  {
    q: '¿Dónde puedo pagar mi crédito?',
    a: (
      <>
        <p>
          Puedes realizar el pago del crédito de tu cuota a través de los
          corresponsales bancarios de Bancolombia con el número de convenio
          92282 y número de referencia generado en tu Silver App. Ten en cuenta
          que los pagos se acreditan en un plazo no mayor a 24 horas.
        </p>
      </>
    ),
  },
];
