import React from 'react';
import {Mision, Portada, Portada2} from './components';
import './somos.css';
import './styles/index';
export const Somos = () => {
  return (
    <section>
      <Portada />
      <Mision />
      <Portada2 />
    </section>
  );
};
