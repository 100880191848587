import React from 'react';
import {Section} from './Section';
import {Terminos} from './Terminos';
import './legal.css';
export const Legal = () => {
  return (
    <section className="legal_section_container_externo">
      <section className="legal_section_container">
        <Section text={'Términos y condiciones'} archivos={Terminos} />
      </section>
    </section>
  );
};
