import React from 'react';
export const Portada = () => {
  return (
    <>
      <section className="portada_section_background">
        <section className="portada_section_info_container">
          <section className="portada_somos_titulos">
            <h1 className="tituloh1_1 titulo">Somos</h1>
            <h1 className="tituloh1_1 titulo">
              <span>Silver App</span>
            </h1>
            <p className="parrafop_1">
              Una aplicación al acceso de todos. Busca fortalecer la inclusión
              financiera en Colombia ofreciendo sus productos de crédito y
              billetera virtual.
            </p>
          </section>
        </section>
      </section>
    </>
  );
};
