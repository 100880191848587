import React from 'react';
import {Portada1} from './components/Portada1';
import {PreguntasSection} from './components/preguntas/PreguntasSection';
import './style';
export const PreguntasFrecuentes = () => {
  return (
    <>
      <Portada1 />
      <PreguntasSection />
    </>
  );
};

//REVISIÓN 1 LISTA
