import React from 'react';
import './styles';
import {Card1} from './components/Card1';
import {Card2} from './components/Card2';
import {Card3} from './components/Card3';
import {SpanMessage} from '../../components/spanMessage/SpanMessage';
export const Home = () => {
  return (
    <section className="home_section_container">
      <Card1 />
      <Card2 />
      <Card3 />
      <SpanMessage />
    </section>
  );
};
// COMPONENTE HOME REVISADO
