import React from 'react';
import man from '../../../assets/img/home/man.svg';
import {
  beneficio1,
  beneficio2,
  beneficio3,
  beneficio4,
} from '../../../assets/icon/home';
export const Card2 = () => {
  return (
    <section className="section_card_2_container">
      <h1 className="tituloh1_2 titulo">
        Ventajas de <span>solicitar</span> tu crédito en Silver App:
      </h1>
      <section className="section_card_2_contenido">
        <div>
          <img src={man} alt="logo" className="card_2_img" />
          <p className="credito_autor_logo">
            <a
              href="https://www.freepik.es/foto-gratis/hombre-guapo-joven-elegante-camisa-blanca
            -chaqueta-negra-tableta-sonriendo-logra-exito-gran-trabajo-expresando-verdaderas-
            emociones-positivas-empresario-trabajador-inteligente_10208782.htm#query=hombre%20con
            %20traje&from_query=hombre%20con%20saco&position=17&from_view=search"
              target="_blank"
              rel="noreferrer"
            >
              Imagen de lookstudio
            </a>{' '}
            en Freepik
          </p>
        </div>
        <div className="section_card_2_contenido_text">
          <Item
            text={'Solicitud de crédito fácil rápido y seguro.'}
            icon={beneficio1}
          />
          <Item
            text={'Proceso 100% digital, sin papeleos.'}
            icon={beneficio2}
          />
          <Item text={'Tasas de interés libre de usura.'} icon={beneficio3} />
          <Item
            text={'Desembolso a tu cuenta bancaria en 24 horas.'}
            icon={beneficio4}
          />
        </div>
      </section>
    </section>
  );
};
const Item = ({icon, text}) => {
  return (
    <div className="card_2_item">
      <img src={icon} alt="logo" className="card_2_item_icon" />
      <div className="card_2_item_text">{text}</div>
    </div>
  );
};
