import {useEffect, useState} from 'react';

/**
 * It's a hook that returns a state and a function to change that state
 * @param limite - The limit of the screen size in pixels.
 * @param [estado=false] - The initial state of the responsive menu.
 * @returns An object with two properties: Responsive and setResponsive.
 */
export const ActiveResponsiveHooks = (limite, estado = false) => {
  const [Responsive, setResponsive] = useState(estado);
  function ResponsiveNavbar(e) {
    if (e.target.innerWidth <= limite) {
    } else {
      setResponsive(false);
    }
    console.log(Responsive);
  }
  useEffect(() => {
    window.addEventListener('resize', ResponsiveNavbar);
    return () => {
      window.removeEventListener('resize', ResponsiveNavbar);
    };
  }, []);
  return {
    Responsive,
    setResponsive,
  };
};
