import React from 'react';
import {icon_email, icon_whatsapp} from '../../../assets/icon/opinion';
export const Formulario = () => {
  return (
    <section className="section_formulario_container">
      <div className="formulario_content">
        <h2 className="formulario_content_title">
          Si tienes alguna duda, inquietud, sugerencia o necesidad,
          <span>
            {' '}
            escríbenos y te responderemos en la mayor brevedad posible:
          </span>
        </h2>
        <section className="formulario_content_info">
          <div className="formulario_content_info_text">
            <p className="formulario_content_info_text_parrafo">
              Como entidad nos importa la opinión de nuestros usuarios, en este
              espacio tendrás la facilidad de contactarte con nuestro equipo y
              lograr resolver tus inquietudes, no dudes en comunicarte con
              nosotros y ayudarnos a alcanzar la excelencia.
            </p>
            <a href="/">
              <div className="formulario_content_info_text_img">
                <img src={icon_email} alt="" />
              </div>
              <div>
                <p>servicio@silverapp.co</p>
                <p>datospersonales@silverapp.co</p>
              </div>
            </a>
            <a
              href="https://wa.me/+573176596134?text=Hola, estoy interesado en..."
              target="_blank"
              rel="noreferrer"
            >
              <div className="formulario_content_info_text_img">
                <img src={icon_whatsapp} alt="" />
              </div>
              <div>
                <p>Linea de whatsApp</p>
                <p>317 6596134</p>
              </div>
            </a>
          </div>
          {/* <form className="formulario_content_info_form">
            <div className="formulario_content_info_form_cont">
              <label>Nombre:</label>
              <input placeholder="Escribe tu nombre completo" />
            </div>
            <div className="formulario_content_info_form_cont">
              <label>Email:</label>
              <input placeholder="Escribe tu correo electrónico" />
            </div>
            <div className="formulario_content_info_form_cont">
              <label>Cédula:</label>
              <input placeholder="Escribe tu número de cédula" />
            </div>
            <div className="formulario_content_info_form_cont">
              <label>Celular</label>
              <input placeholder="Escribe tu número de celular" />
            </div>
            <div className="formulario_content_info_form_cont">
              <label>Comentario/Solicitud</label>
              <textarea rows="6" />
            </div>
            <div className="formulario_content_info_form_check">
              <input type="checkbox" />
              <label>
                Acepto la política de tratamiento y protección de datos
                personales.
              </label>
            </div>
            <div className="formulario_content_info_form_boton">
              <button>Enviar</button>
            </div>
          </form> */}
        </section>
      </div>
    </section>
  );
};
