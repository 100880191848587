import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {googleSpan} from '../../assets/logo/spanMessage';
import Logohorizontal_silver_app from '../../assets/logo/nav/Logohorizontal_silver_app.svg';
import './spanMessag.css';
export const SpanMessage = () => {
  const [SpanMessage, setSpanMessage] = useState(true);
  const handleSpanMessage = () => {
    setSpanMessage(false);
  };
  return (
    <div
      className="span-message-container"
      style={SpanMessage ? null : {display: 'none'}}
    >
      <div className="span-message">
        <img
          src={Logohorizontal_silver_app}
          className="logo-span-message"
          alt="logo"
        />
        <ul>
          <li>
            <a href="https://play.google.com/store/apps/details?id=co.silvercredit.silverapp"
              target="_blank"
            >
              <img src={googleSpan} alt="Descarga google play" />
            </a>
          </li>
          <li className="Icon-span" onClick={handleSpanMessage}>
            <div className="Icon1-1"></div>
            <div className="Icon1-2"></div>
          </li>
        </ul>
      </div>
    </div>
  );
};
