import React from 'react';
import portadaImg from '../../../assets/img/opiniones/portada.svg';
export const Portada = () => {
  return (
    <section className="opinion_portada_Container">
      <div className="opinion_portada_Content">
        <div className="opinion_portada_text">
          <h1 className="tituloh1_1 titulo">
            <span>¡Tu opinión es importante</span>
          </h1>
          <h2>ayudanos a mejorar!</h2>
        </div>
        <img
          src={portadaImg}
          alt="imagen de la portada"
          className="opinion_portada_img"
        />
      </div>
    </section>
  );
};
