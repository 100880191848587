import React from 'react';
import {pensante} from '../../../assets/img/preguntas';
export const Portada1 = () => {
  return (
    <section className="portada_1_section_contaier">
      <div className="portada_1_text">
        <h1 className="titulo tituloh1_1">
          ¿Tienes alguna <span> Duda?</span>
        </h1>
        <p className="parrafop_1">
          En este espacio encontrarás las preguntas frecuentes, además como
          entidad <span>podemos ayudarte.</span>
        </p>
      </div>
      <img
        src={pensante}
        alt="pensando en los cŕeditos"
        className="portada_1_img"
      />
      <p className="credito_autor_portada_pregunta">
        <a
          href="https://www.freepik.es/foto-gratis/hombre-serio-penando_920606.htm#&position=0&
          from_view=author"
          target="_blank"
          rel="noreferrer"
        >
          Imagen de asierromero
        </a>{' '}
        en Freepik
      </p>
    </section>
  );
};

//REVISIÓN 1 LISTA
