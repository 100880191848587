import arrow from '../../../../assets/icon/arrow.svg';
import {usePreguntas} from './js/usePreguntas';
import {Respuesta} from './Respuesta';
export const Preguntas = () => {
  const {abrir, seleccionado, preguntasFiltradas, mostrarPregunta} =
    usePreguntas();
  return (
    <section className="preguntas_section_contenedor_externo">
      <section className="preguntas_section_contenedor">
        {preguntasFiltradas?.map((pregunta, indice) => (
          <article key={indice}>
            <div
              onClick={() => mostrarPregunta(pregunta, indice)}
              className={`${
                seleccionado === indice && abrir ? 'pregunta_Activa' : ''
              } pregunta`}
            >
              {pregunta.q}
              <img
                src={arrow}
                alt="icon_arrow"
                className={`arrow_icon ${
                  seleccionado === indice && abrir ? 'arrow_up' : 'arrow_down'
                }`}
              />
            </div>
            {abrir && seleccionado === indice ? <Respuesta /> : null}
          </article>
        ))}
      </section>
    </section>
  );
};
