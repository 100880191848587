import React, {useEffect} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';
import Logohorizontal_silver_app from '../../assets/logo/nav/Logohorizontal_silver_app.svg';
import {ActiveResponsiveHooks} from '../../hooks/ActiveResponsiveHooks';
import './navbar.css';
export const NavBar = () => {
  const {Responsive: NavBarOpen, setResponsive: setNavBarOpen} =
    ActiveResponsiveHooks(1000);
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    setNavBarOpen(false);
  }, [location]);
  return (
    <div className="navbar_container">
      <nav className="official_navbar">
        <div className="app_logo_navBar">
          <Link to="/">
            <img
              src={Logohorizontal_silver_app}
              alt="logo"
              className="logo_silver"
            />
          </Link>
          <div
            onClick={() => {
              setNavBarOpen(!NavBarOpen);
            }}
            className="responsive_navbar_icon"
          >
            <div></div>
            <div></div>
            <div></div>
            {/* <i
              className={
                !NavBarOpen
                  ? 'fa-solid fa-door-open'
                  : 'fa-solid fa-door-closed'
              }
            ></i> */}
          </div>
        </div>
        <ul
          className={`links_elements_navbar ${
            NavBarOpen ? 'NavbarOn' : 'NavBar-Off'
          }`}
        >
          <li>
            <NavLink to="/somos" className="link_element_navbar">
              Quiénes Somos
            </NavLink>
          </li>
          <li>
            <NavLink to="/legal" className="link_element_navbar">
              Términos y condiciones
            </NavLink>
          </li>
          <li>
            <NavLink to="/preguntas" className="link_element_navbar">
              Preguntas Frecuentes
            </NavLink>
          </li>
          <li>
            <NavLink to="/opiniones" className="link_element_navbar">
              Tu opinión
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};
