import {contextPreguntas} from '../../../../context/preguntasContext';
import {usePreguntasSection} from './js/usePreguntasSection';
import {Preguntas} from './Preguntas';

/*
CONTEXT:
  En el context se agregan 2 variables y una función.
    La función "SeleccionarPregunta" y "PreguntasFiltradas" la implementamos directamente en el Componente Preguntas.jsx
    "respuesta" lo llamamos en el componente Respuesta.jsx
*/
export const PreguntasSection = () => {
  const {
    inputPregunta,
    respuesta,
    preguntasFiltradas,
    seleccionarPregunta,
    onChangeInputPregunta,
  } = usePreguntasSection();
  return (
    <>
      <contextPreguntas.Provider
        value={{
          respuesta,
          preguntasFiltradas,
          seleccionarPregunta,
        }}
      >
        <div className="buscador_pregunta_contenedor">
          <h1>Cuentanos tu problema</h1>
          <input
            placeholder='Ejemplo: "Opciones de crédito"'
            value={inputPregunta}
            onChange={onChangeInputPregunta}
          />
        </div>
        <Preguntas />
      </contextPreguntas.Provider>
    </>
  );
};

// REVISION 2 LISTA
