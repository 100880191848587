import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {NavBar} from './components/navBar/NavBar';
import {Footer} from './components/footer/Footer';
import {Home, Legal, Somos, Opiniones, PreguntasFrecuentes} from './pages';

export const SilverApp = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/somos" element={<Somos />} />
        <Route path="/opiniones" element={<Opiniones />} />
        <Route path="/preguntas" element={<PreguntasFrecuentes />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};
