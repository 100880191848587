import React from 'react';

export const Mision = () => {
  return (
    <section className="somos_section_container">
      <section className="mision_section_container">
        <h1 className="tituloh1_2 titulo">
          Conoce nuestra <span>identidad</span> coorporativa
        </h1>
        <div className="mision_section_content">
          <div className="mision_section_info">
            <h2 className="titulo">
              <span>Misión</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
              aliquam sit nibh diam ac quam nibh a bibendum. Enim consectetur
              tempus tempor morbi aenean mi.
            </p>
          </div>
          <div className="mision_section_info">
            <h2 className="titulo">
              <span>Visión</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
              aliquam sit nibh diam ac quam nibh a bibendum. Enim consectetur
              tempus tempor morbi aenean mi.
            </p>
          </div>
        </div>
      </section>
    </section>
  );
};

// REVISION 1 LISTA
