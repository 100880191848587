import React from 'react';
import {
  aplicar,
  descargar,
  recibir,
  registro,
} from '../../../assets/icon/home/cardSlice';
const cards = [
  {
    title: 'Descarga la APP',
    text: 'Ingresa a tu tienda de aplicaciones busca Silver App y descarga la app.',
    icon: descargar,
  },
  {
    title: 'Registrate',
    text: 'Llena la información que te solicita Silver App, recuerda verificar tus datos.',
    icon: registro,
  },
  {
    title: 'Aplica',
    text: 'Una vez registrado tendrás un cupón disponible, si necesitas más podrás aplicar.',
    icon: aplicar,
  },
  {
    title: 'Recibe',
    text: 'Una vez aprobada tu solicitud se desembolsará el crédito en tu entidad bancaria. ',
    icon: recibir,
  },
];
export const Card3 = () => {
  return (
    <section className="card_slice_section_container">
      <h1 className="tituloh1_2 titulo">
        Como solicitar
        <span> tu crédito </span>
        en Silver App
      </h1>
      <section className="card_slice_content">
        {cards.map((card, indx) => {
          return (
            <Card
              text={card.text}
              title={card.title}
              key={indx}
              icon={card.icon}
            />
          );
        })}
      </section>
    </section>
  );
};
const Card = ({icon, title, text}) => {
  return (
    <div className="card_slice_Card">
      <img
        src={icon}
        alt="icono de la imange"
        className="card_slice_card_img"
      />
      <div className="card_slice_card_text">
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
};

// REVISION 1 LISTA
