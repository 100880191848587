import React from 'react';
import celularApp from '../../../assets/img/home/Celular encabezado@4x-8.png';
export const Card1 = () => {
  return (
    <section className='section_card_dad'>
      <section className="section_card_1">
        <div className="card_1_text">
          <h1 className="tituloh1_1 titulo">
            <span>Tu Solución</span>
          </h1>
          <h1 className="tituloh1_1 titulo">Digital Fácil</h1>
          <p className="parrafop_1">Créditos al alcance de todos.</p>
        </div>
        <div className="card_1_img">
          <img src={celularApp} alt="logo" className="logo_silver" />
        </div>
      </section>
    </section>
  );
};
// REVISIÓN 1 LISTA
