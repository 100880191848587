import {useContext, useState} from 'react';
import {contextPreguntas} from '../../../../../context/preguntasContext';
/**
 * It returns an object with the state of the accordion, the selected question, the filtered questions,
 * and a function to show the question
 *
 * - preguntasFiltradas: retornas las preguntas filtradas, las cuales se modifican en el input
 * - mostrarPregunta :
 * - seleccionado y abrir: retorna el estado de Abrir que al igual del seleccionado, me permiten identificar cual de las preguntas se selecciono, y si se quiere abrir o cerrar
 */
export const usePreguntas = () => {
  const {preguntasFiltradas, seleccionarPregunta} =
    useContext(contextPreguntas);
  const [seleccionado, setSeleccionado] = useState(null);
  const [abrir, setAbrir] = useState(false);
  const mostrarPregunta = (pregunta, indice) => {
    if (indice === seleccionado) {
      setAbrir(!abrir);
    } else {
      setAbrir(true);
      seleccionarPregunta(pregunta);
      setSeleccionado(indice);
    }
  };
  return {
    abrir,
    seleccionado,
    preguntasFiltradas,
    mostrarPregunta,
  };
};
