import {useContext} from 'react';
import {contextPreguntas} from '../../../../context/preguntasContext';

export const Respuesta = () => {
  const {respuesta} = useContext(contextPreguntas);
  return (
    <section className="respuesta_contenedor">
      <div>{respuesta?.a}</div>
    </section>
  );
};
